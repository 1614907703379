import React, { Component } from "react";
import "../@ui/styles/Loader-styles.css";

class Svganimation extends Component {
  render() {
    return (
      <div>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 400 400"
          enable-background="new 0 0 400 400"
        >
          <g id="jjperezaguinaga">
            <g id="airplane2">
              <g>
                <path
                  fill="#F15C27"
                  d="M236.783,38.819c-27.964-6.462-57.599-5.521-85.059,2.874l0.206,0.674
				c27.354-8.361,56.879-9.295,84.733-2.853C236.676,39.276,236.709,39.041,236.783,38.819z"
                />
                <path
                  fill="#EE7D8F"
                  d="M236.756,40.347c-34.021-7.978-70.586-4.699-102.564,9.443l0.406,0.923
				c32.142-14.219,68.961-17.389,103.1-9.111C237.215,41.334,236.902,40.873,236.756,40.347z"
                />
                <path
                  fill="#E8D320"
                  d="M243.795,43.947c-0.73-0.284-1.404-0.626-2.121-0.892C201.906,32.281,158.058,37.35,122,57.389
				l0.485,0.882c36.504-20.287,81.056-25.147,121.159-13.634l0.193-0.679C243.822,43.952,243.811,43.952,243.795,43.947z"
                />
                <path
                  fill="#2D948D"
                  d="M243.232,46.077c-30.975-8.823-64.655-8.02-95.111,2.486l-0.181-0.523
				c30.565-10.542,64.361-11.349,95.445-2.495L243.232,46.077z"
                />
                <path
                  fill="#69C8C3"
                  d="M242.934,47.121c-39.451-11.327-83.298-6.547-119.202,13.412l-0.376-0.685
				C159.44,39.791,203.5,34.986,243.148,46.37L242.934,47.121z"
                />
              </g>
              <g>
                <path
                  fill="#69C8C3"
                  d="M254.555,46.239l-4.512-8.032c-0.181-0.32-0.611-0.677-0.959-0.791l-1.148-0.382
				c-0.352-0.115-0.541,0.074-0.426,0.425l2.426,7.373c0.113,0.35,0.494,0.715,0.849,0.816l3.457,0.989
				C254.594,46.739,254.734,46.56,254.555,46.239z"
                />
                <path
                  fill="#2D948D"
                  d="M252.951,49.896l-8.418,3.741c-0.337,0.149-0.896,0.177-1.244,0.061l-1.148-0.381
				c-0.35-0.116-0.389-0.381-0.088-0.593l6.354-4.46c0.299-0.212,0.823-0.278,1.17-0.147l3.36,1.274
				C253.281,49.52,253.289,49.747,252.951,49.896z"
                />
                <g>
                  <g>
                    <path
                      fill="#2D948D"
                      d="M250.949,44.656c-4.021-1.323-8.056-2.614-12.099-3.868c-0.899-0.28-1.746,0.136-2.35,0.787
						c-0.601,0.64-0.873,1.533-0.188,2.505c0.931,1.313,2.078,1.545,3.041,1.881c4.219,1.478,8.457,2.933,12.693,4.322
						c4.565,1.497,8.256,2.585,12.85,4.013c0.502,0.157,1.23-0.004,1.404-0.441c0.488-1.229-0.715-3.32-0.965-3.642
						c-0.646-0.816-1.254-1.086-1.896-1.311C258.979,47.34,255.439,46.134,250.949,44.656z"
                    />
                  </g>
                </g>
                <path
                  fill="#2D948D"
                  d="M235.941,43.594l-0.674-6.528c-0.022-0.216,0.127-0.337,0.332-0.27l1.154,0.385
				c0.207,0.067,0.461,0.277,0.569,0.466l1.953,3.417c0.107,0.188,0.039,0.422-0.155,0.522l-2.861,1.461
				C236.064,43.146,235.965,43.811,235.941,43.594z"
                />
                <g>
                  <circle fill="#FFFFFF" cx="252.673" cy="47.702" r="0.554" />
                  <circle fill="#FFFFFF" cx="255.49" cy="48.635" r="0.554" />
                  <circle fill="#FFFFFF" cx="258.305" cy="49.571" r="0.554" />
                  <path
                    fill="#FFFFFF"
                    d="M261.646,50.679c-0.096,0.29-0.41,0.446-0.701,0.352c-0.291-0.096-0.446-0.411-0.35-0.7
					c0.094-0.292,0.408-0.448,0.699-0.353C261.586,50.076,261.744,50.387,261.646,50.679z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M250.385,46.941c-0.096,0.291-0.41,0.448-0.701,0.352c-0.289-0.097-0.445-0.41-0.352-0.701
					c0.1-0.29,0.41-0.447,0.701-0.351C250.324,46.337,250.482,46.65,250.385,46.941z"
                  />
                  <circle fill="#FFFFFF" cx="247.043" cy="45.833" r="0.554" />
                  <circle fill="#FFFFFF" cx="244.229" cy="44.898" r="0.554" />
                  <circle fill="#FFFFFF" cx="241.412" cy="43.963" r="0.554" />
                </g>
                <path
                  fill="#2D948D"
                  d="M238.443,37.706c-0.073,0.223-0.313,0.342-0.534,0.271l-3.619-1.202
				c-0.224-0.074-0.345-0.313-0.271-0.536l0,0c0.074-0.223,0.314-0.341,0.537-0.268l3.617,1.199
				C238.396,37.245,238.518,37.485,238.443,37.706L238.443,37.706z"
                />
              </g>
            </g>
            <g id="airplane1">
              <g>
                <path
                  fill="#F15C27"
                  d="M300.649,326.339c-32.001,24.181-73.167,35.484-113.023,31.668c0.024,0.234,0.023,0.472-0.014,0.707
				c40.01,3.818,81.335-7.536,113.459-31.811L300.649,326.339z"
                />
                <path
                  fill="#EE7D8F"
                  d="M319.285,307.528c-33.812,35.615-84.521,53.887-133.252,48.537c0.009,0.002,0.017,0.002,0.024,0.008
				c0.666,0.227,1.096,0.648,1.342,1.146c48.581,4.86,98.932-13.513,132.611-48.988L319.285,307.528z"
                />
                <path
                  fill="#E8D320"
                  d="M330.949,291.716c-17.326,22.97-40.897,41.193-67.568,52.011c-26.635,10.93-56.24,14.246-84.685,9.918
				l-0.074,0.494c1.526,0.452,3.088,0.772,4.636,1.145c27.162,3.32,55.166-0.229,80.503-10.625
				c26.839-10.885,50.558-29.223,67.991-52.336L330.949,291.716z"
                />
                <path
                  fill="#2D948D"
                  d="M178.923,352.165c44.001,6.967,90.593-5.731,124.836-34.244l0.35,0.429
				c-34.357,28.61-81.12,41.356-125.269,34.362L178.923,352.165z"
                />
                <path
                  fill="#69C8C3"
                  d="M179.087,351.091c27.993,4.261,57.12,0.996,83.321-9.758c26.236-10.639,49.433-28.571,66.479-51.174
				l0.623,0.471c-17.131,22.714-40.439,40.734-66.81,51.427c-26.332,10.807-55.604,14.086-83.732,9.809L179.087,351.091z"
                />
              </g>
              <path
                fill="#2D948D"
                d="M169.401,353.132l5.165,7.628c0.207,0.305,0.665,0.622,1.023,0.709l1.176,0.284
			c0.358,0.086,0.532-0.118,0.388-0.459l-3.03-7.143c-0.144-0.34-0.554-0.674-0.915-0.746l-3.525-0.695
			C169.321,352.636,169.194,352.827,169.401,353.132z"
              />
              <path
                fill="#69C8C3"
                d="M170.694,349.353l8.076-4.431c0.324-0.176,0.876-0.25,1.236-0.163l1.176,0.284
			c0.358,0.086,0.418,0.349,0.136,0.582l-5.958,4.976c-0.282,0.237-0.799,0.346-1.153,0.244l-3.457-0.99
			C170.397,349.757,170.371,349.528,170.694,349.353z"
              />
              <g>
                <g>
                  <path
                    fill="#69C8C3"
                    d="M173.126,354.407c4.118,0.984,8.244,1.934,12.378,2.848c0.919,0.203,1.728-0.282,2.275-0.979
					c0.543-0.688,0.744-1.603-0.023-2.512c-1.035-1.232-2.198-1.367-3.187-1.625c-4.326-1.117-8.672-2.214-13.01-3.246
					c-4.674-1.111-8.443-1.888-13.139-2.929c-0.514-0.112-1.226,0.109-1.363,0.562c-0.384,1.262,0.989,3.248,1.268,3.545
					c0.709,0.762,1.339,0.977,1.997,1.15C164.9,352.401,168.529,353.31,173.126,354.407z"
                  />
                </g>
              </g>
              <path
                fill="#69C8C3"
                d="M188.169,354.214l1.216,6.449c0.041,0.215-0.097,0.347-0.309,0.297l-1.184-0.287
			c-0.211-0.051-0.481-0.235-0.605-0.416l-2.229-3.243c-0.125-0.181-0.075-0.416,0.11-0.533l2.729-1.693
			C188.084,354.671,188.13,354.003,188.169,354.214z"
              />
              <g>
                <circle fill="#2D948D" cx="171.153" cy="351.516" r="0.554" />
                <path
                  fill="#2D948D"
                  d="M167.731,350.692c0.072-0.299,0.37-0.482,0.668-0.412c0.297,0.072,0.479,0.373,0.407,0.67
				s-0.371,0.48-0.668,0.41C167.842,351.286,167.66,350.989,167.731,350.692z"
                />
                <circle fill="#2D948D" cx="165.386" cy="350.125" r="0.554" />
                <circle fill="#2D948D" cx="162.502" cy="349.426" r="0.555" />
                <circle fill="#2D948D" cx="174.036" cy="352.213" r="0.553" />
                <path
                  fill="#2D948D"
                  d="M176.381,352.78c0.073-0.299,0.372-0.482,0.669-0.408c0.298,0.07,0.479,0.369,0.407,0.668
				c-0.072,0.297-0.37,0.479-0.667,0.41C176.492,353.376,176.31,353.077,176.381,352.78z"
                />
                <circle fill="#2D948D" cx="179.803" cy="353.605" r="0.554" />
                <circle fill="#2D948D" cx="182.687" cy="354.303" r="0.555" />
              </g>
              <path
                fill="#69C8C3"
                d="M186.167,360.292c0.055-0.229,0.285-0.367,0.511-0.315l3.707,0.897c0.227,0.056,0.368,0.283,0.312,0.511
			l0,0c-0.056,0.229-0.283,0.366-0.513,0.312l-3.704-0.893C186.253,360.745,186.113,360.517,186.167,360.292L186.167,360.292z"
              />
            </g>
            <g id="innerCircles">
              <path
                id="circle5"
                fill="#F15C27"
                d="M288.569,200.171c0.433-34.354-21.302-67.555-52.857-81.048
			c-31.26-14.176-70.434-7.837-95.479,15.701c-25.674,22.849-35.486,61.307-24.13,93.701c10.636,32.639,41.765,57.229,76.03,59.846
			l-0.126,1.414c-34.818-2.658-66.439-27.643-77.248-60.807c-11.542-32.912-1.567-71.987,24.517-95.2
			c25.45-23.912,65.248-30.357,97.012-15.952c32.061,13.708,54.142,47.439,53.703,82.346L288.569,200.171L288.569,200.171z"
              />
              <path
                id="circle4"
                fill="#EE7D8F"
                d="M284.937,200.171c0.051-19.738-7.062-39.39-19.792-54.483
			c-12.639-15.128-30.668-25.74-50.131-29.101c-19.402-3.526-40.029-0.038-57.123,9.838c-17.177,9.729-30.664,25.691-37.525,44.189
			c-6.875,18.503-7.045,39.381-0.405,57.977c6.559,18.623,19.868,34.726,36.884,44.724c16.958,10.125,37.526,13.873,56.979,10.652
			c19.481-3.146,37.719-13.39,50.528-28.398l1.535,1.322c-13.113,15.362-31.785,25.854-51.738,29.076
			c-19.91,3.297-40.973-0.544-58.336-10.908c-17.423-10.238-31.045-26.717-37.763-45.791c-6.799-19.045-6.625-40.406,0.415-59.358
			c7.028-18.945,20.833-35.28,38.419-45.242c17.505-10.111,38.625-13.685,58.484-10.072c19.932,3.44,38.391,14.306,51.329,29.793
			c13.03,15.447,20.319,35.581,20.264,55.783H284.937z"
              />
              <path
                id="circle3"
                fill="#E8D320"
                d="M282.087,200.171c0.102-21.531-8.755-42.883-24.042-58.042
			c-15.16-15.285-36.51-24.141-58.042-24.042c-21.531-0.1-42.881,8.755-58.042,24.042c-15.287,15.16-24.142,36.511-24.041,58.042
			c-0.101,21.531,8.755,42.882,24.042,58.041c15.16,15.289,36.511,24.144,58.042,24.043c21.532,0.101,42.881-8.756,58.042-24.043
			C273.332,243.052,282.188,221.702,282.087,200.171h2.024c0.105,22.058-8.974,43.947-24.634,59.476
			c-15.526,15.66-37.418,24.738-59.475,24.635c-22.057,0.104-43.946-8.973-59.475-24.635c-15.66-15.527-24.739-37.418-24.635-59.476
			c-0.104-22.056,8.975-43.946,24.634-59.474c15.527-15.662,37.419-24.74,59.476-24.637c22.057-0.104,43.948,8.975,59.475,24.637
			c15.66,15.527,24.739,37.418,24.634,59.474H282.087z"
              />
              <path
                id="circle1"
                fill="#2D948D"
                d="M279.073,200.171c0.505-32.562-21.546-63.749-52.265-74.399
			c-30.48-11.412-67.283-1.406-87.706,23.976c-21.112,24.755-24.097,62.852-7.185,90.62c16.173,28.275,50.92,43.988,82.834,37.48
			l0.207,1.094c-32.358,6.604-67.604-9.334-84-38.008c-17.153-28.164-14.124-66.791,7.285-91.898
			c20.709-25.737,58.032-35.887,88.942-24.312c31.152,10.802,53.509,42.428,53.001,75.448H279.073z"
              />
              <path
                id="circle2_1_"
                fill="#69C8C3"
                d="M276.892,200.171c0.088-20.185-8.191-40.148-22.521-54.367
			c-14.218-14.326-34.185-22.608-54.368-22.52c-20.187-0.089-40.15,8.193-54.368,22.52c-14.327,14.219-22.609,34.184-22.52,54.367
			c-0.09,20.187,8.192,40.148,22.52,54.367c14.219,14.326,34.183,22.609,54.368,22.521c20.186,0.088,40.15-8.195,54.368-22.521
			C268.699,240.319,276.979,220.354,276.892,200.171h1.57c0.092,20.593-8.362,40.978-22.979,55.478
			c-14.504,14.617-34.887,23.074-55.479,22.979c-20.591,0.095-40.976-8.36-55.479-22.979
			c-14.616-14.502-23.071-34.887-22.979-55.479c-0.093-20.592,8.362-40.976,22.979-55.478c14.503-14.617,34.888-23.074,55.479-22.98
			c20.592-0.092,40.975,8.363,55.479,22.98c14.617,14.502,23.071,34.886,22.979,55.478L276.892,200.171L276.892,200.171z"
              />
            </g>
          </g>
          <g id="Timeless_Design">
            <g>
              <polygon
                points="191.413,255.582 190.346,255.157 189.391,255.794 189.466,254.649 188.565,253.937 189.677,253.654 
			190.075,252.577 190.688,253.549 191.836,253.594 191.101,254.476 		"
              />
              <polygon
                points="195.247,256.165 194.49,257.028 194.266,255.902 193.211,255.448 194.213,254.887 194.318,253.744 
			195.162,254.523 196.282,254.269 195.801,255.312 196.388,256.299 		"
              />
              <polygon
                points="200.338,256.381 199.829,257.41 199.322,256.381 198.184,256.215 199.007,255.414 198.813,254.282 
			199.829,254.817 200.846,254.282 200.652,255.414 201.475,256.215 		"
              />
              <polygon
                points="210.186,254.649 210.261,255.794 209.305,255.157 208.24,255.582 208.551,254.476 207.817,253.594 
			208.964,253.549 209.576,252.577 209.975,253.654 211.087,253.937 		"
              />
              <polygon
                points="205.16,256.846 204.404,255.983 203.264,256.117 203.851,255.131 203.369,254.088 204.49,254.342 
			205.333,253.562 205.439,254.706 206.44,255.266 205.386,255.72 		"
              />
            </g>
            <g>
              <g>
                <line
                  fill="#FFFF"
                  x1="136.654"
                  y1="231.395"
                  x2="287.233"
                  y2="198.513"
                />
                <text
                  transform="matrix(0.977 -0.2134 0 1 149.3367 226.363)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  R
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 165.5945 222.6863)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  I
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 175.5847 220.6306)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  T
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 190.0623 217.4695)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  E
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 205.6252 214.5085)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  S
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 220.5266 211.9099)"
                  font-family="'Pathway Gothic One'"
                  font-size="39.6581"
                >
                  H
                </text>
                {/* <text transform="matrix(0.977 -0.2133 0 1 226.9495 209.4148)" font-family="'Pathway Gothic One'" font-size="39.6581">E</text>
			<text transform="matrix(0.977 -0.2133 0 1 238.844 206.8171)" font-family="'Pathway Gothic One'" font-size="39.6581">R</text> */}
              </g>
              <g>
                {/* <line fill="#fff" x1="170.574" y1="189.775" x2="300.712" y2="176.861"/> */}
                <text
                  transform="matrix(0.977 -0.2134 0 1 151.6272 187.7834)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  D
                </text>
                <text
                  transform="matrix(0.977 -0.2134 0 1 158.1804 186.5706)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  E
                </text>
                <text
                  transform="matrix(0.977 -0.2134 0 1 163.8459 185.552)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  V
                </text>
                <text
                  transform="matrix(0.977 -0.2134 0 1 170.1052 184.4036)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  E
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 177.1046 183.2244)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  L
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 182.8552 181.6189)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  O
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 189.1198 180.6003)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  P
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 194.8635 179.0886)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  E
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 200.8635 178.0286)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  R
                </text>
                {/* <text transform="matrix(0.977 -0.2133 0 1 190.8635 180.0086)" font-family="'Pathway Gothic One'" font-size="15.5752">O</text> */}
                {/* <text transform="matrix(0.977 -0.2133 0 1 192.8650 180.0886)" font-family="'Pathway Gothic One'" font-size="15.5752">P</text>
			<text transform="matrix(0.977 -0.2133 0 1 195.8660 180.0886)" font-family="'Pathway Gothic One'" font-size="15.5752">E</text>
			<text transform="matrix(0.977 -0.2133 0 1 196.8670 180.0886)" font-family="'Pathway Gothic One'" font-size="15.5752">R</text> */}
              </g>
              <g>
                <line
                  fill="none"
                  x1="213.236"
                  y1="234.207"
                  x2="272.375"
                  y2="221.292"
                />
                {/* <text transform="matrix(0.977 -0.2133 0 1 218.2815 232.2166)" font-family="'Pathway Gothic One'" font-size="15.5752"> </text>
			<text transform="matrix(0.977 -0.2134 0 1 220.6321 231.7029)" font-family="'Pathway Gothic One'" font-size="15.5752"> </text> */}
                <text
                  transform="matrix(0.977 -0.2133 0 1 222.9778 231.1912)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  R
                </text>
                <text
                  transform="matrix(0.977 -0.2134 0 1 228.7874 230.1404)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  A
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 234.1858 228.9607)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  W
                </text>
                <text
                  transform="matrix(0.977 -0.2133 0 1 242.8342 227.7273)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  A
                </text>
                <text
                  transform="matrix(0.977 -0.2134 0 1 248.5217 226.4861)"
                  font-family="'Pathway Gothic One'"
                  font-size="15.5752"
                >
                  L
                </text>
              </g>

              <rect
                x="152.606"
                y="219.751"
                transform="matrix(0.9774 -0.2115 0.2115 0.9774 -42.0389 47.9254)"
                width="100.736"
                height="1.385"
              />
              <polyline points="202.615,221.111 199.696,225.441 196.699,221.142 		" />

              <rect
                x="146.7"
                y="180.439"
                transform="matrix(-0.9774 0.2115 -0.2115 -0.9774 427.992 316.4788)"
                width="100.736"
                height="1.385"
              />
              <polyline points="196.699,180.647 199.619,176.315 202.615,180.615 		" />
              <g>
                <polygon
                  points="191.413,146.273 190.346,146.698 189.391,146.06 189.466,147.206 188.565,147.917 189.677,148.2 
				190.075,149.277 190.688,148.306 191.836,148.261 191.101,147.378 			"
                />
                <polygon
                  points="195.247,145.69 194.49,144.826 194.266,145.952 193.211,146.406 194.213,146.967 194.318,148.111 
				195.162,147.332 196.282,147.584 195.801,146.542 196.388,145.555 "
                />
                <polygon
                  points="200.338,145.474 199.829,144.445 199.322,145.474 198.184,145.639 199.007,146.441 198.813,147.572 
				199.829,147.038 200.846,147.572 200.652,146.441 201.475,145.639"
                />
                <polygon
                  points="210.186,147.206 210.261,146.06 209.305,146.698 208.24,146.273 208.551,147.378 207.817,148.261 
				208.964,148.306 209.576,149.277 209.975,148.2 211.087,147.917 "
                />
                <polygon
                  points="205.16,145.008 204.404,145.871 203.264,145.737 203.851,146.724 203.369,147.766 204.49,147.513 
				205.333,148.292 205.439,147.15 206.44,146.588 205.386,146.135"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default Svganimation;
